<template>
  <v-container fluid class="pl-12 pr-12">
    <ValidationForms ref="forms">
      <v-layout column wrap class="mx-auto">
        <v-card-title class="font-weight-black pl-3 mb-6" style="font-size:32px"
          >{{ $t('link.costAggeregate') }}
        </v-card-title>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.agencyName') }}</p>
        </v-row>

        <v-row class="ml-8 mb-6" no-gutters>
          <v-flex md4>
            <v-select
              v-model="conditions.agencyId"
              item-text="text"
              item-value="value"
              :items="agenciesIdList"
              :value="conditions.agencyId"
              dense
              outlined
              clearable
              hide-details
            />
          </v-flex>
        </v-row>

        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.targetMonth') }}</p>
          <v-chip
            class="ma-2 font-weight-black"
            color="next"
            dark
            label
            small
            >{{ $t('label.required') }}</v-chip
          >
        </v-row>
        <v-row class="ml-8 mb-6">
          <v-flex md4>
            <ValidationForm rules="required">
              <VueMonthlyPicker
                :max="maxDate"
                dateFormat="YYYY-MM"
                selectedBackgroundColor="#007bff"
                :value="conditions.month"
                :clearOption="false"
                @selected="monthSelect"
              ></VueMonthlyPicker>
            </ValidationForm>
          </v-flex>
        </v-row>
        <v-row no-gutters class="ml-8">
          <p class="title my-auto">{{ $t('label.sendMethod') }}</p>
          <v-chip
            class="ma-2 font-weight-black"
            color="next"
            dark
            label
            small
            >{{ $t('label.required') }}</v-chip
          >
        </v-row>
        <v-row no-gutters class="ml-8 mb-6">
          <ValidationForm rules="selectRequired">
            <v-row
              v-for="(item, index) in sendCheckList"
              :key="index"
              class="ml-0"
            >
              <input
                type="checkbox"
                class="my-auto"
                id="mail"
                v-model="conditions.sendFlg"
                :value="item.value"
              />
              <label class="ml-1">{{ item.text }}</label>
            </v-row>
          </ValidationForm>
        </v-row>
        <v-row>
          <v-btn
            class="font-weight-black ml-8"
            align="center"
            width="30vw"
            max-width="170px"
            color="next"
            style="font-size:20px"
            :dark="!isCsvExport"
            :disabled="isCsvExport"
            @click="csvDownload"
            >{{ $t('button.csvDownload') }}</v-btn
          >
        </v-row>
        <ErrorDialog ref="errorDialog"></ErrorDialog>
      </v-layout>
    </ValidationForms>
    <section></section>
  </v-container>
</template>

<script>
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import VueMonthlyPicker from 'vue-monthly-picker';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import { getAgencyIdList, getCostAggeregate } from '@/apis/agency/agencies';
import moment from 'moment';
import {
  CostAggeregateCsv,
  OfficeType,
  ReverseSenderFlg,
  ReverseSendCode,
  SenderFlg,
} from '@/lib/const';
import { downloadCsv, getCsvFileName } from '@/lib/csv';

export default {
  name: 'CostAggeregate',
  components: {
    ValidationForms,
    ValidationForm,
    VueMonthlyPicker,
    ErrorDialog,
  },
  data() {
    return {
      conditions: {
        month: moment()
          .subtract(1, 'months')
          .format('YYYY-MM'),
        agencyId: null,
        sendFlg: [],
      },
      agenciesIdList: [],
      menu: false,
      maxDate: moment()
        .subtract(1, 'months')
        .format('YYYY-MM'),
      sendCheckList: [
        {
          value: SenderFlg.Mail,
          text: this.$t('checkbox.mail'),
        },
        {
          value: SenderFlg.Sms,
          text: this.$t('checkbox.sms'),
        },
      ],
      sendOfficeList: null,
      isCsvExport: false,
    };
  },
  methods: {
    sendFlgToString() {
      return this.sendFlg ? this.$t('label.mail') : this.$t('label.sms');
    },
    monthSelect(value) {
      // フォーマットを表示内容と統一
      this.$set(this.conditions, 'month', moment(value).format('YYYY-MM'));
    },
    mapSender(sender) {
      return {
        agencyName: sender.agencyName,
        agencyCode: sender.agencyCode,
        sendFlg: ReverseSenderFlg[sender.sendFlg],
        sendCode: ReverseSendCode[sender.sendCode],
        count: sender.count,
        totalPriceInUSD: sender.totalPriceInUSD,
      };
    },
    async csvDownload() {
      // 連打を防止するためCSV出力ボタンを無効化
      this.$set(this, 'isCsvExport', true);
      if (await this.$refs.forms.validate()) {
        this.$set(
          this,
          'sendOfficeList',
          await getCostAggeregate(this.conditions)
        );
        // 対象が0件の場合エラーメッセージを表示してCSV出力を行わない
        if (this.sendOfficeList.length <= 0) {
          this.$set(this, 'isCsvExport', false);
          this.$refs.errorDialog.open(
            this.$t('title.agency.csvExportError'),
            this.$t('error.notFoundSendList')
          );
          return;
        }

        const headers = {};
        CostAggeregateCsv.headers.forEach((header, index) => {
          // ヘッダー名をセットする
          headers[CostAggeregateCsv.columns[index]] = header;
        });

        const rows = this.sendOfficeList.map(sender => {
          const row = {};
          const senderData = this.mapSender(sender);
          CostAggeregateCsv.columns.forEach(column => {
            // 送信情報をセットする
            row[column] = senderData[column];
          });

          return row;
        });

        await downloadCsv(
          rows,
          headers,
          getCsvFileName(
            this.$t('link.costAggeregate') + '_' + this.conditions.month
          )
        );
      }
      this.$set(this, 'isCsvExport', false);
    },
  },
  async mounted() {
    const agencies = await getAgencyIdList();
    const agenciesIdList = agencies.map(agency => ({
      text: agency.agencyName,
      value: agency.id,
    }));
    this.$set(this, 'agenciesIdList', agenciesIdList);
  },
};
</script>
